import cn              from 'classnames'
import FormSend_SVG    from './svg/icons/FormSend_SVG'
import FormSuccess_SVG from './svg/icons/FormSuccess_SVG'
import React           from 'react'
import styles          from '../styles/components/SendButton.module.sass'

const SendButton = ({
	                    sendingState,
	                    onClick,
	                    className,
                    }) => {
	return <button
		className={cn(styles.send_button, className, {
			[styles.sent]: sendingState
			               === 'sent',
		})}
		type={'button'}
		disabled={sendingState
		          !== 'none'}

		onClick={onClick}
	>
		{(
			 sendingState
			 === 'none'
			 || sendingState
			 === 'sending'
		 )
		 && <>
			 {/* Icon */}
			 <FormSend_SVG
				 middleDotClassName={styles.middle_dot}
				 className={styles.icon}
			 />

			 {/* Text */}
			 <span className={styles.text}>Envoyer</span>
		 </>}
		{sendingState
		 === 'sent'
		 && <>
			 {/* Icon */}
			 <FormSuccess_SVG
				 className={styles.icon}
			 />

			 {/* Text */}
			 <span className={styles.text}>Message bien envoyé</span>
		 </>}
	</button>
}

export default SendButton
