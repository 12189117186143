const FormSuccess_SVG = ({...props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24.367'
			height='18.753'
			viewBox='0 0 24.367 18.753'
			stroke='#ededed'

			{...props}
		>
			<path
				id='Path_161'
				data-name='Path 161'
				d='M37.15,34.595s5.957,2.431,7.591,8.586c1.49-6.15,3.88-11.172,15.536-17.028'
				transform='translate(-36.584 -24.812)'
				fill='none'
				strokeWidth='3'
			/>
		</svg>
	)
}

export default FormSuccess_SVG
