const SocialLogo_SVG = ( { ...props } ) => {
	return (
			<svg
					id="INCOME_SOCIAL_JAUNE"
					data-name="INCOME SOCIAL_JAUNE"
					xmlns="http://www.w3.org/2000/svg"
					width="135.905"
					height="42"
					viewBox="0 0 135.905 42"
					
					{...props}
			>
				<path
						id="Path_91"
						data-name="Path 91"
						d="M23.529,60.939A4.908,4.908,0,0,1,18.6,56.01V41.6h4.484V55.634a1.753,1.753,0,0,0,1.746,1.746h.479v3.56Z"
						transform="translate(-17.98 -33.111)"
				/>
				<path
						id="Path_92"
						data-name="Path 92"
						d="M19.634,22.516a2.1,2.1,0,0,1-.479-.034,2.839,2.839,0,0,1-1.677-4.655A2.846,2.846,0,0,1,19.668,16.8a2.1,2.1,0,0,1,.479.034,2.839,2.839,0,0,1,1.677,4.655,2.846,2.846,0,0,1-2.191,1.027Z"
						transform="translate(-16.79 -16.8)"
				/>
				<path
						id="Path_93"
						data-name="Path 93"
						d="M184.519,43.2c-4.176,0-4.176,2.67-4.176,2.875,0,.685,0,2.772,4.176,2.772a11.268,11.268,0,0,0,4.518-.958s-.411-4.689-4.518-4.689M182.74,60c-7.017,0-10.268-5.34-10.44-10.713h4.107c.205,3.32,1.985,6.983,6.538,6.983a5.541,5.541,0,0,0,5.682-4.347,20.99,20.99,0,0,1-4.45.65c-7.256,0-7.564-5.648-7.564-6.469,0-3.32,2.122-6.606,7.941-6.606,6.23,0,8.044,6.366,8.044,6.366s2.122-1.54,2.122-5.579h5.408a2.039,2.039,0,0,1,.65.856s1.164-1.643,5.066-1.643c5.134,0,6.23,2.464,6.23,2.464s1.2-2.464,6.229-2.464a8.487,8.487,0,0,1,8.489,8.489V59.626h-4.484V47.989a3.97,3.97,0,0,0-7.941,0V59.626h-4.484V47.989a4,4,0,0,0-2.7-3.8,4.085,4.085,0,0,0-1.266-.205,3.973,3.973,0,0,0-3.97,3.97V59.592h-4.484V45.045s-1.2,3.936-4.142,5.477c-.1,5.75-4.176,9.481-10.576,9.481"
						transform="translate(-119.068 -31.729)"
				/>
				<path
						id="Path_94"
						data-name="Path 94"
						d="M116.774,59.937C110.3,59.937,106.3,56,106.3,49.668c0-6.23,4.107-10.268,10.474-10.268,3.389,0,6.2,1.13,7.872,3.217a7.918,7.918,0,0,1,1.472,6.64h-5.066a8.623,8.623,0,0,0,.308-1.985c0-1.4-.993-4.107-4.792-4.107-3.286,0-5.237,2.362-5.237,6.3,0,4.176,1.883,6.743,5.134,6.743a5.1,5.1,0,0,0,4.758-2.67H126.6c-.411,2.328-3.525,6.4-9.823,6.4"
						transform="translate(-75.661 -31.664)"
				/>
				<path
						id="Path_95"
						data-name="Path 95"
						d="M349.168,43.131c-2.5,0-4.518,1.13-5.032,3.8a4.529,4.529,0,0,0-.068.753h9.892v-.65c-.171-3.081-3.081-3.9-4.792-3.9m.205,16.806C342.9,59.937,338.9,56,338.9,49.668c0-6.23,4.107-10.268,10.474-10.268,3.423,0,6.093,1.1,7.736,3.149a9.588,9.588,0,0,1,1.506,7.975H344.068c0,.308.171,5.682,5,5.682a5.088,5.088,0,0,0,4.758-2.67H359.2c-.411,2.328-3.56,6.4-9.824,6.4"
						transform="translate(-228.641 -31.664)"
				/>
				<path
						id="Path_96"
						data-name="Path 96"
						d="M57.181,59.426V47.891a4.251,4.251,0,0,0-3.32-4.039,4.194,4.194,0,0,0-.65-.068,3.973,3.973,0,0,0-3.97,3.97V59.392H44.722V47.686c0-.205-.205-4.86-2.122-7.6h4.826a2.611,2.611,0,0,1,.685.958s1.54-1.746,5.066-1.746a8.487,8.487,0,0,1,8.489,8.489V59.426Z"
						transform="translate(-33.766 -31.598)"
				/>
				<path
						id="Path_97"
						data-name="Path 97"
						d="M406.9,91.893a1.381,1.381,0,0,1-.445.445,1.195,1.195,0,0,1-1.232,0,1.382,1.382,0,0,1-.445-.445,1.195,1.195,0,0,1,0-1.232,1.382,1.382,0,0,1,.445-.445,1.195,1.195,0,0,1,1.232,0,1.381,1.381,0,0,1,.445.445,1.195,1.195,0,0,1,0,1.232m.479-1.266a2.958,2.958,0,0,0-.342-.548,1.491,1.491,0,0,0-.548-.342,1.613,1.613,0,0,0-1.3,0,1.918,1.918,0,0,0-.513.342,1.49,1.49,0,0,0-.342.548,1.613,1.613,0,0,0,0,1.3,1.838,1.838,0,0,0,.342.548,1.685,1.685,0,0,0,.513.342,1.613,1.613,0,0,0,1.3,0,1.918,1.918,0,0,0,.513-.342,1.49,1.49,0,0,0,.342-.548,2,2,0,0,0,.137-.65,1.372,1.372,0,0,0-.1-.65"
						transform="translate(-271.59 -64.681)"
				/>
				<path
						id="Path_98"
						data-name="Path 98"
						d="M407.611,92.508h.24c.068,0,.1,0,.137.034a.242.242,0,0,1-.137.411h-.24Zm.821.479a.582.582,0,0,0,0-.548.351.351,0,0,0-.205-.171.645.645,0,0,0-.274-.068H407.2v1.54h.411v-.513h.24l.24.513h.445l-.308-.582a1.085,1.085,0,0,0,.205-.171"
						transform="translate(-273.563 -66.391)"
				/>
				<g
						id="Group_20"
						data-name="Group 20"
						transform="translate(94.856 35.052)"
				>
					<path
							id="Path_99"
							data-name="Path 99"
							d="M296.3,126.148a2.732,2.732,0,0,1-1.232-.24,2.24,2.24,0,0,1-.821-.65,1.909,1.909,0,0,1-.342-.958h.821a1.019,1.019,0,0,0,.274.616,1.709,1.709,0,0,0,.582.377,2.67,2.67,0,0,0,.753.137,2.071,2.071,0,0,0,.821-.137,1.269,1.269,0,0,0,.582-.445,1.053,1.053,0,0,0,.205-.65.777.777,0,0,0-.205-.548,1.718,1.718,0,0,0-.479-.342,3.65,3.65,0,0,0-.65-.24l-.822-.24a3.012,3.012,0,0,1-1.232-.65,1.4,1.4,0,0,1-.445-1.1,1.63,1.63,0,0,1,.308-.993,1.938,1.938,0,0,1,.821-.65,2.946,2.946,0,0,1,2.328,0,2.11,2.11,0,0,1,.787.616,1.478,1.478,0,0,1,.308.89h-.787a1.078,1.078,0,0,0-.479-.787,2.169,2.169,0,0,0-1.814-.137,1.245,1.245,0,0,0-.513.411.9.9,0,0,0-.171.582.805.805,0,0,0,.205.582,1.354,1.354,0,0,0,.513.342c.205.068.376.137.548.205l.685.205a2.387,2.387,0,0,1,.582.205,2.773,2.773,0,0,1,.616.342,1.372,1.372,0,0,1,.479.513,1.417,1.417,0,0,1,.205.787,1.963,1.963,0,0,1-.274.993,2.013,2.013,0,0,1-.821.685A2.743,2.743,0,0,1,296.3,126.148Z"
							transform="translate(-293.9 -119.2)"
					/>
					<path
							id="Path_100"
							data-name="Path 100"
							d="M318.178,126.214a2.879,2.879,0,0,1-1.54-.411,3.026,3.026,0,0,1-1.061-1.2,3.88,3.88,0,0,1-.376-1.848,4.337,4.337,0,0,1,.376-1.848,3.026,3.026,0,0,1,1.061-1.2,3.093,3.093,0,0,1,3.081,0,3.026,3.026,0,0,1,1.061,1.2,3.879,3.879,0,0,1,.377,1.848,4.45,4.45,0,0,1-.377,1.848,2.606,2.606,0,0,1-1.061,1.2A3.221,3.221,0,0,1,318.178,126.214Zm0-.787a1.954,1.954,0,0,0,1.1-.308,2.11,2.11,0,0,0,.787-.924,3.234,3.234,0,0,0,.274-1.472,3.764,3.764,0,0,0-.274-1.472,2.111,2.111,0,0,0-.787-.924,2.036,2.036,0,0,0-1.1-.308,1.955,1.955,0,0,0-1.1.308,2.111,2.111,0,0,0-.787.924,3.67,3.67,0,0,0,0,2.944,2.111,2.111,0,0,0,.787.924A2.036,2.036,0,0,0,318.178,125.427Z"
							transform="translate(-307.909 -119.266)"
					/>
					<path
							id="Path_101"
							data-name="Path 101"
							d="M342.878,126.214a2.879,2.879,0,0,1-1.54-.411,3.026,3.026,0,0,1-1.061-1.2,3.88,3.88,0,0,1-.377-1.848,4.336,4.336,0,0,1,.377-1.848,2.788,2.788,0,0,1,1.061-1.2,2.879,2.879,0,0,1,1.54-.411,2.953,2.953,0,0,1,.958.137,2.39,2.39,0,0,1,.822.445,2.942,2.942,0,0,1,.616.685,2.718,2.718,0,0,1,.342.924h-.821a1.657,1.657,0,0,0-.24-.616,1.382,1.382,0,0,0-.445-.445,1.648,1.648,0,0,0-.548-.274,1.993,1.993,0,0,0-.65-.1,1.955,1.955,0,0,0-1.1.308,2.111,2.111,0,0,0-.787.924,3.234,3.234,0,0,0-.274,1.472,3.764,3.764,0,0,0,.274,1.472,2.111,2.111,0,0,0,.787.924,2.036,2.036,0,0,0,1.1.308,2.149,2.149,0,0,0,.65-.1,1.648,1.648,0,0,0,.548-.274,2.738,2.738,0,0,0,.445-.445,1.657,1.657,0,0,0,.24-.616h.821a2.717,2.717,0,0,1-.342.924,2.385,2.385,0,0,1-.616.685,3.151,3.151,0,0,1-.822.445A4.314,4.314,0,0,1,342.878,126.214Z"
							transform="translate(-324.155 -119.266)"
					/>
					<path
							id="Path_102"
							data-name="Path 102"
							d="M365.221,119.6v6.709H364.4V119.6Z"
							transform="translate(-340.269 -119.463)"
					/>
					<path
							id="Path_103"
							data-name="Path 103"
							d="M374.856,126.309H374l2.464-6.709h.822l2.464,6.709h-.856l-2.019-5.648h-.068Zm.308-2.636h3.423v.719h-3.423Z"
							transform="translate(-346.583 -119.463)"
					/>
					<path
							id="Path_104"
							data-name="Path 104"
							d="M398,126.309V119.6h.821v5.99h3.115v.719Z"
							transform="translate(-362.369 -119.463)"
					/>
				</g>
			</svg>
	)
}

export default SocialLogo_SVG
