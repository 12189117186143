const Phone_SVG = ({
	                   ringClassName,
	                   ...props
                   }) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='37.922'
			height='37.722'
			viewBox='0 0 37.922 37.722'

			{...props}
		>
			<g
				id='Group_180'
				data-name='Group 180'
				transform='translate(0 3.722)'
			>
				<g
					id='Group_180-2'
					data-name='Group 180'
				>
					<path
						id='Path_146'
						data-name='Path 146'
						d='M33.993,27.394a5.029,5.029,0,0,0-1.542-3.456,12.351,12.351,0,0,0-4.89-3.249,5.473,5.473,0,0,0-5.62,1.595l-.008.008-1.481,1.47-.7.086a27.4,27.4,0,0,1-5.272-4.153L14.3,19.52a27.41,27.41,0,0,1-4.153-5.271l.086-.7,1.47-1.481.008-.008a5.473,5.473,0,0,0,1.595-5.62,12.352,12.352,0,0,0-3.249-4.89,5.053,5.053,0,0,0-7.013-.253l-.038.034-.052.049A10.775,10.775,0,0,0,0,9.269c.027,5.46,3.028,11.7,8.026,16.7.943.943,3.184,2.773,3.184,2.773l1.616-2.108S10.765,24.953,9.9,24.092C5.389,19.577,2.679,14.031,2.655,9.256A8.2,8.2,0,0,1,4.809,3.278l.01-.009a2.389,2.389,0,0,1,3.327.12c3.438,3.566,3.189,5.249,1.67,6.807L7.981,12.013,7.567,14.8a29.219,29.219,0,0,0,4.857,6.6l.175.175a31.111,31.111,0,0,0,6.476,4.881l2.822-.378,1.9-1.9c1.558-1.519,3.241-1.768,6.807,1.67a2.389,2.389,0,0,1,.12,3.327l-.009.01A8.18,8.18,0,0,1,24.8,31.341h-.061c-1.957-.01-6.553-1.551-6.553-1.551l-1.052,2.439S22.383,33.985,24.727,34H24.8a10.751,10.751,0,0,0,7.818-2.955l.049-.052.034-.038a5.029,5.029,0,0,0,1.289-3.558'
						transform='translate(0.004 0.001)'
						fill='#d36f3b'
					/>
				</g>
			</g>
			<g
				id='Groupe_182'
				data-name='Groupe 182'
				transform='translate(0 0.458)'
				className={ringClassName}
			>
				<path
					id='Path_149'
					data-name='Path 149'
					d='M19.428,7.267,23.7-3.72'
					transform='matrix(0.995, -0.105, 0.105, 0.995, -2.059, 6.177)'
					fill='none'
					stroke='#d36f3b'
					strokeWidth='2'
				/>
				<path
					id='Path_150'
					data-name='Path 150'
					d='M23.7-3.72,19.428,7.267'
					transform='translate(20.139 -2.013) rotate(54)'
					fill='none'
					stroke='#d36f3b'
					strokeWidth='2'
				/>
				<path
					id='Path_151'
					data-name='Path 151'
					d='M21.316,3.491,23.7-3.72'
					transform='matrix(0.866, 0.5, -0.5, 0.866, 8.244, -2.564)'
					fill='none'
					stroke='#d36f3b'
					strokeWidth='2'
				/>
			</g>
		</svg>
	)
}

export default Phone_SVG
