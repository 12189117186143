const FormSend_SVG = ({
	                      middleDotClassName,
	                      ...props
                      }) => {
	return (
		<svg
			id="Group_185"
			data-name="Group 185"
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"

			{...props}
		>
			<g
				id="Group_184"
				data-name="Group 184"
			>
				<path
					id="Path_152"
					data-name="Path 152"
					d="M1.254,24a1.112,1.112,0,0,1-.787-1.9L2.82,19.749l-.338-.44A11.973,11.973,0,0,1,0,12,31.085,31.085,0,0,1,.218,8.795l2.008.261A29.412,29.412,0,0,0,2.024,12a9.96,9.96,0,0,0,2.546,6.658A1.65,1.65,0,0,1,4.527,20.9L3.485,21.976h8.509a9.971,9.971,0,0,0,9.981-9.9,10.128,10.128,0,0,0-2.6-7.264,9.926,9.926,0,0,0-7.339-2.784h-.032A9.143,9.143,0,0,0,8.169,3.148,4.46,4.46,0,0,0,5.823,6.021a4.719,4.719,0,0,0,.828,3.295,6.068,6.068,0,0,0-.93.7,6.441,6.441,0,0,0-.616.636A6.809,6.809,0,0,1,3.835,5.644,6.306,6.306,0,0,1,7.167,1.389,11.109,11.109,0,0,1,11.952,0h.055a11.9,11.9,0,0,1,8.827,3.4A12.073,12.073,0,0,1,24,11.981a11.779,11.779,0,0,1-.943,4.648,12.186,12.186,0,0,1-6.425,6.417A11.912,11.912,0,0,1,11.943,24Z"
				/>
				<path
					id="Path_153"
					data-name="Path 153"
					d="M192.053,325.6a1.576,1.576,0,1,1,1.576,1.576,1.576,1.576,0,0,1-1.576-1.576"
					transform="translate(-185.314 -312.652)"
				/>
				<g
					className={middleDotClassName}
				>
					<path
						id="Path_154"
						data-name="Path 154"
						d="M321.512,325.6a1.576,1.576,0,1,1,1.576,1.576,1.576,1.576,0,0,1-1.576-1.576"
						transform="translate(-310.231 -312.652)"
					/>
				</g>
				<path
					id="Path_155"
					data-name="Path 155"
					d="M450.593,325.6a1.576,1.576,0,1,1,1.576,1.576,1.576,1.576,0,0,1-1.576-1.576"
					transform="translate(-434.782 -312.652)"
				/>
			</g>
		</svg>

	)
}

export default FormSend_SVG
