const Location_SVG = ({...props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24.296'
			height='31.999'
			viewBox='0 0 24.296 31.999'

			{...props}
		>
			<g
				id='Group_181'
				data-name='Group 181'
			>
				<path
					id='Path_147'
					data-name='Path 147'
					d='M11.471,32a33.335,33.335,0,0,1-5.715-5.521,28.059,28.059,0,0,1-3.938-6.242A19.665,19.665,0,0,1,0,12.148a12.148,12.148,0,1,1,24.3,0,23.856,23.856,0,0,1-3.4,12.639,15.563,15.563,0,0,1-3.522,4.058l-.687-.939-.69-.935a13.7,13.7,0,0,0,2.994-3.524,21.187,21.187,0,0,0,2.983-11.3,9.823,9.823,0,1,0-19.646,0,17.347,17.347,0,0,0,1.6,7.1,25.675,25.675,0,0,0,3.591,5.705,31.626,31.626,0,0,0,5.31,5.152Z'
				/>
				<path
					id='Path_148'
					data-name='Path 148'
					d='M100.215,106.5a5.882,5.882,0,1,1,5.882-5.882,5.889,5.889,0,0,1-5.882,5.882m0-9.439a3.557,3.557,0,1,0,3.557,3.557,3.561,3.561,0,0,0-3.557-3.557'
					transform='translate(-88.067 -88.443)'
				/>
			</g>
		</svg>
	)
}

export default Location_SVG
