const ConseilLogo_SVG = ( { ...props } ) => {
	return (
			<svg
					id="INCOME_CONSEIL_COULEUR"
					data-name="INCOME CONSEIL_COULEUR"
					xmlns="http://www.w3.org/2000/svg"
					width="135.964"
					height="42"
					viewBox="0 0 135.964 42"
					
					{...props}
			>
				<path
						id="Path_76"
						data-name="Path 76"
						d="M19.634,22.516a2.105,2.105,0,0,1-.479-.034,2.839,2.839,0,0,1-1.677-4.655A2.846,2.846,0,0,1,19.668,16.8a2.1,2.1,0,0,1,.479.034,2.839,2.839,0,0,1,1.677,4.655,2.846,2.846,0,0,1-2.191,1.027Z"
						transform="translate(-16.79 -16.8)"
				/>
				<g
						id="Group_18"
						data-name="Group 18"
						transform="translate(0.62 7.702)"
				>
					<path
							id="Path_77"
							data-name="Path 77"
							d="M23.529,60.94A4.909,4.909,0,0,1,18.6,56.011V41.6h4.484V55.634A1.753,1.753,0,0,0,24.83,57.38h.479v3.56Z"
							transform="translate(-18.6 -40.813)"
					/>
					<path
							id="Path_78"
							data-name="Path 78"
							d="M184.52,43.2c-4.176,0-4.176,2.67-4.176,2.875,0,.685,0,2.773,4.176,2.773a11.268,11.268,0,0,0,4.518-.958s-.411-4.689-4.518-4.689M182.74,60c-7.017,0-10.269-5.34-10.44-10.714h4.108c.205,3.32,1.985,6.983,6.538,6.983a5.542,5.542,0,0,0,5.682-4.347,20.99,20.99,0,0,1-4.45.65c-7.257,0-7.565-5.648-7.565-6.469,0-3.32,2.122-6.606,7.941-6.606,6.23,0,8.044,6.367,8.044,6.367s2.122-1.54,2.122-5.579h5.408a2.039,2.039,0,0,1,.65.856s1.164-1.643,5.066-1.643c5.134,0,6.23,2.465,6.23,2.465s1.2-2.465,6.23-2.465a8.487,8.487,0,0,1,8.489,8.489V59.627h-4.484V47.989a3.971,3.971,0,1,0-7.941,0V59.627h-4.484V47.989a4,4,0,0,0-2.7-3.8,4.085,4.085,0,0,0-1.266-.205,3.973,3.973,0,0,0-3.971,3.971V59.593h-4.484V45.045s-1.2,3.936-4.142,5.477c-.1,5.751-4.176,9.482-10.577,9.482"
							transform="translate(-119.688 -39.432)"
					/>
					<path
							id="Path_79"
							data-name="Path 79"
							d="M116.774,59.938C110.3,59.938,106.3,56,106.3,49.669c0-6.23,4.108-10.269,10.474-10.269,3.389,0,6.2,1.13,7.873,3.218a7.918,7.918,0,0,1,1.472,6.641h-5.066a8.623,8.623,0,0,0,.308-1.985c0-1.4-.993-4.108-4.792-4.108-3.286,0-5.237,2.362-5.237,6.3,0,4.176,1.883,6.743,5.134,6.743a5.1,5.1,0,0,0,4.758-2.67H126.6c-.411,2.328-3.526,6.4-9.824,6.4"
							transform="translate(-76.28 -39.366)"
					/>
					<path
							id="Path_80"
							data-name="Path 80"
							d="M349.169,43.131c-2.5,0-4.518,1.13-5.032,3.8a4.527,4.527,0,0,0-.068.753h9.892v-.65c-.171-3.081-3.081-3.9-4.792-3.9m.205,16.807C342.9,59.938,338.9,56,338.9,49.669c0-6.23,4.108-10.269,10.474-10.269,3.423,0,6.093,1.1,7.736,3.149a9.588,9.588,0,0,1,1.506,7.975H344.069c0,.308.171,5.682,5,5.682a5.088,5.088,0,0,0,4.758-2.67H359.2c-.411,2.328-3.56,6.4-9.824,6.4"
							transform="translate(-229.262 -39.366)"
					/>
					<path
							id="Path_81"
							data-name="Path 81"
							d="M57.182,59.427V47.892a4.251,4.251,0,0,0-3.32-4.039,4.194,4.194,0,0,0-.65-.068,3.973,3.973,0,0,0-3.971,3.971V59.393H44.722V47.686c0-.205-.205-4.861-2.122-7.6h4.826a2.611,2.611,0,0,1,.685.958s1.54-1.746,5.066-1.746a8.487,8.487,0,0,1,8.489,8.489V59.427Z"
							transform="translate(-34.385 -39.3)"
					/>
					<path
							id="Path_82"
							data-name="Path 82"
							d="M406.9,91.893a1.381,1.381,0,0,1-.445.445,1.195,1.195,0,0,1-1.232,0,1.381,1.381,0,0,1-.445-.445,1.195,1.195,0,0,1,0-1.232,1.381,1.381,0,0,1,.445-.445,1.195,1.195,0,0,1,1.232,0,1.381,1.381,0,0,1,.445.445,1.195,1.195,0,0,1,0,1.232m.479-1.266a2.955,2.955,0,0,0-.342-.548,1.491,1.491,0,0,0-.548-.342,1.613,1.613,0,0,0-1.3,0,1.919,1.919,0,0,0-.513.342,1.491,1.491,0,0,0-.342.548,1.613,1.613,0,0,0,0,1.3,1.84,1.84,0,0,0,.342.548,1.685,1.685,0,0,0,.513.342,1.613,1.613,0,0,0,1.3,0,1.919,1.919,0,0,0,.513-.342,1.491,1.491,0,0,0,.342-.548,2,2,0,0,0,.137-.65,1.373,1.373,0,0,0-.1-.65"
							transform="translate(-272.21 -72.383)"
					/>
					<path
							id="Path_83"
							data-name="Path 83"
							d="M407.611,92.508h.24c.068,0,.1,0,.137.034a.242.242,0,0,1-.137.411h-.24Zm.821.479a.582.582,0,0,0,0-.548.351.351,0,0,0-.205-.171.645.645,0,0,0-.274-.068H407.2v1.54h.411v-.513h.24l.24.513h.445l-.308-.582a1.084,1.084,0,0,0,.205-.171"
							transform="translate(-274.183 -74.093)"
					/>
				</g>
				<g
						id="Group_19"
						data-name="Group 19"
						transform="translate(88.967 35.086)"
				>
					<path
							id="Path_84"
							data-name="Path 84"
							d="M279.644,126.246a2.76,2.76,0,0,1-1.506-.411,3.026,3.026,0,0,1-1.061-1.2,4.289,4.289,0,0,1-.377-1.814,4.177,4.177,0,0,1,.377-1.814,2.788,2.788,0,0,1,1.061-1.2,2.844,2.844,0,0,1,1.506-.411,2.953,2.953,0,0,1,.958.137,2.391,2.391,0,0,1,.822.445,1.886,1.886,0,0,1,.582.685,3.694,3.694,0,0,1,.342.924h-.822a1.657,1.657,0,0,0-.24-.616,1.83,1.83,0,0,0-.445-.445,1.647,1.647,0,0,0-.548-.274,1.993,1.993,0,0,0-.65-.1,2.036,2.036,0,0,0-1.1.308,2.218,2.218,0,0,0-.787.89,3.145,3.145,0,0,0-.274,1.472,3.765,3.765,0,0,0,.274,1.472,2.014,2.014,0,0,0,.787.89,2.036,2.036,0,0,0,1.1.308,2.15,2.15,0,0,0,.65-.1,1.647,1.647,0,0,0,.548-.274,2.737,2.737,0,0,0,.445-.445,1.658,1.658,0,0,0,.24-.616h.822a2.718,2.718,0,0,1-.342.924,2.917,2.917,0,0,1-.582.685,3.152,3.152,0,0,1-.822.445A2.953,2.953,0,0,1,279.644,126.246Z"
							transform="translate(-276.7 -119.366)"
					/>
					<path
							id="Path_85"
							data-name="Path 85"
							d="M303.344,126.246a2.76,2.76,0,0,1-1.506-.411,3.026,3.026,0,0,1-1.061-1.2,4.289,4.289,0,0,1-.377-1.814,4.177,4.177,0,0,1,.377-1.814,2.789,2.789,0,0,1,1.061-1.2,2.844,2.844,0,0,1,1.506-.411,2.76,2.76,0,0,1,1.506.411,3.026,3.026,0,0,1,1.061,1.2,3.827,3.827,0,0,1,.377,1.814,4.177,4.177,0,0,1-.377,1.814,2.789,2.789,0,0,1-1.061,1.2A2.844,2.844,0,0,1,303.344,126.246Zm0-.753a2.036,2.036,0,0,0,1.1-.308,2.218,2.218,0,0,0,.787-.89,3.234,3.234,0,0,0,.274-1.472,3.764,3.764,0,0,0-.274-1.472,1.829,1.829,0,0,0-.787-.89,2.1,2.1,0,0,0-2.191,0,2.219,2.219,0,0,0-.787.89,3.128,3.128,0,0,0-.308,1.472,3.225,3.225,0,0,0,.308,1.472,2.014,2.014,0,0,0,.787.89A1.954,1.954,0,0,0,303.344,125.493Z"
							transform="translate(-292.288 -119.366)"
					/>
					<path
							id="Path_86"
							data-name="Path 86"
							d="M330.94,119.7v6.675h-.787l-3.628-5.237h-.1v5.237H325.6V119.7h.787l3.663,5.271h.068V119.7Z"
							transform="translate(-308.862 -119.563)"
					/>
					<path
							id="Path_87"
							data-name="Path 87"
							d="M351.6,126.214a2.733,2.733,0,0,1-1.232-.24,2.24,2.24,0,0,1-.822-.65,1.8,1.8,0,0,1-.342-.958h.822a1.02,1.02,0,0,0,.274.616,1.431,1.431,0,0,0,.582.377,2.67,2.67,0,0,0,.753.137,2.072,2.072,0,0,0,.822-.137,1.488,1.488,0,0,0,.582-.411,1.053,1.053,0,0,0,.205-.65.947.947,0,0,0-.171-.548,1.718,1.718,0,0,0-.479-.342,3.651,3.651,0,0,0-.65-.24l-.822-.24a3.012,3.012,0,0,1-1.232-.65,1.4,1.4,0,0,1-.445-1.1,1.7,1.7,0,0,1,.308-.993,1.938,1.938,0,0,1,.822-.65,2.946,2.946,0,0,1,2.328,0,2.11,2.11,0,0,1,.787.616,1.406,1.406,0,0,1,.308.89H353.2a.958.958,0,0,0-.479-.753,2.169,2.169,0,0,0-1.814-.137,1.515,1.515,0,0,0-.513.411.841.841,0,0,0-.171.582.864.864,0,0,0,.205.582,1.354,1.354,0,0,0,.513.342c.205.068.377.137.548.205l.685.205a2.387,2.387,0,0,1,.582.205,2.775,2.775,0,0,1,.616.342,1.372,1.372,0,0,1,.479.513,1.418,1.418,0,0,1,.205.787,1.84,1.84,0,0,1-.274.958,1.814,1.814,0,0,1-.822.685A3.6,3.6,0,0,1,351.6,126.214Z"
							transform="translate(-324.384 -119.3)"
					/>
					<path
							id="Path_88"
							data-name="Path 88"
							d="M371.2,126.375V119.7h4.039v.719h-3.218v2.225h3.012v.719h-3.012v2.293h3.286v.719Z"
							transform="translate(-338.853 -119.563)"
					/>
					<path
							id="Path_89"
							data-name="Path 89"
							d="M392.121,119.7v6.675H391.3V119.7Z"
							transform="translate(-352.073 -119.563)"
					/>
					<path
							id="Path_90"
							data-name="Path 90"
							d="M402.5,126.375V119.7h.822v5.956h3.115v.719Z"
							transform="translate(-359.439 -119.563)"
					/>
				</g>
			</svg>
	)
}

export default ConseilLogo_SVG
