const MainPage_ContactUs_TitleLine_SVG = ( { ...props } ) => {
	return (
			<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 399.898 15.598"
					
					{ ...props }
			>
				<path
						id="Path_114"
						data-name="Path 114"
						d="M-19886.078,4634.354c34.119,9.347,103.658,6.585,203.563,0s186.891-4.976,193.438,8.176"
						transform="translate(19886.738 -4628.046)"
						fill="none"
						strokeWidth="5"
				/>
			</svg>
	)
}

export default MainPage_ContactUs_TitleLine_SVG
