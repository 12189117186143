const AuditLogo_SVG = ( { ...props } ) => {
	return ( <svg
					xmlns="http://www.w3.org/2000/svg"
					width="127.373"
					height="40"
					viewBox="0 0 127.373 40"
					
					{...props}
			>
				<g
						id="INCOME_AUDIT_COULEUR"
						data-name="INCOME AUDIT_COULEUR"
						transform="translate(-16.79 -16.8)"
				>
					<path
							id="Path_63"
							data-name="Path 63"
							d="M23.218,59.718A4.6,4.6,0,0,1,18.6,55.1V41.6h4.2V54.747a1.642,1.642,0,0,0,1.635,1.635h.449v3.335Z"
							transform="translate(-1.23 -16.615)"
					/>
					<path
							id="Path_64"
							data-name="Path 64"
							d="M19.455,22.155a1.972,1.972,0,0,1-.449-.032,2.66,2.66,0,0,1-1.571-4.361,2.667,2.667,0,0,1,2.052-.962,1.972,1.972,0,0,1,.449.032,2.66,2.66,0,0,1,1.571,4.361,2.667,2.667,0,0,1-2.052.962Z"
							transform="translate(0)"
					/>
					<path
							id="Path_65"
							data-name="Path 65"
							d="M183.748,42.963c-3.912,0-3.912,2.5-3.912,2.694,0,.641,0,2.6,3.912,2.6a10.557,10.557,0,0,0,4.233-.9s-.385-4.393-4.233-4.393M182.08,58.708c-6.574,0-9.62-5-9.78-10.037h3.848c.192,3.11,1.86,6.542,6.125,6.542A5.191,5.191,0,0,0,187.6,51.14a19.664,19.664,0,0,1-4.169.609c-6.8,0-7.087-5.291-7.087-6.061,0-3.11,1.988-6.189,7.44-6.189,5.836,0,7.536,5.964,7.536,5.964s1.988-1.443,1.988-5.227h5.067a1.91,1.91,0,0,1,.609.8s1.09-1.539,4.746-1.539c4.81,0,5.836,2.309,5.836,2.309S210.684,39.5,215.4,39.5a7.951,7.951,0,0,1,7.953,7.953v10.9h-4.2v-10.9a3.72,3.72,0,1,0-7.44,0v10.9h-4.2v-10.9a3.75,3.75,0,0,0-2.533-3.559,3.826,3.826,0,0,0-1.186-.192,3.722,3.722,0,0,0-3.72,3.72v10.9h-4.2V44.695s-1.122,3.688-3.88,5.131c-.1,5.387-3.912,8.883-9.909,8.883"
							transform="translate(-105.643 -15.196)"
					/>
					<path
							id="Path_66"
							data-name="Path 66"
							d="M116.112,58.64c-6.061,0-9.812-3.688-9.812-9.62,0-5.836,3.848-9.62,9.812-9.62,3.175,0,5.8,1.058,7.375,3.014a7.418,7.418,0,0,1,1.379,6.221h-4.746a8.077,8.077,0,0,0,.289-1.86c0-1.315-.93-3.848-4.489-3.848-3.078,0-4.906,2.213-4.906,5.9,0,3.912,1.764,6.317,4.81,6.317a4.779,4.779,0,0,0,4.457-2.5h5.035c-.385,2.181-3.3,6-9.2,6"
							transform="translate(-60.807 -15.129)"
					/>
					<path
							id="Path_67"
							data-name="Path 67"
							d="M348.52,42.9c-2.341,0-4.233,1.058-4.714,3.559a4.242,4.242,0,0,0-.064.705h9.267v-.609c-.16-2.886-2.886-3.656-4.489-3.656m.192,15.745c-6.061,0-9.812-3.688-9.812-9.62,0-5.836,3.848-9.62,9.812-9.62,3.207,0,5.708,1.026,7.247,2.95a8.982,8.982,0,0,1,1.411,7.472H343.742c0,.289.16,5.323,4.682,5.323a4.767,4.767,0,0,0,4.457-2.5h5.035c-.385,2.181-3.335,6-9.2,6"
							transform="translate(-218.82 -15.129)"
					/>
					<path
							id="Path_68"
							data-name="Path 68"
							d="M56.26,58.155V47.349a3.982,3.982,0,0,0-3.11-3.784,3.929,3.929,0,0,0-.609-.064,3.722,3.722,0,0,0-3.72,3.72v10.9H44.588V47.156c0-.192-.192-4.553-1.988-7.119h4.521a2.446,2.446,0,0,1,.641.9A6.416,6.416,0,0,1,52.509,39.3a7.951,7.951,0,0,1,7.953,7.953v10.9Z"
							transform="translate(-17.534 -15.066)"
					/>
					<path
							id="Path_69"
							data-name="Path 69"
							d="M406.733,91.748a1.294,1.294,0,0,1-.417.417,1.119,1.119,0,0,1-1.154,0,1.3,1.3,0,0,1-.417-.417,1.119,1.119,0,0,1,0-1.154,1.294,1.294,0,0,1,.417-.417,1.119,1.119,0,0,1,1.154,0,1.294,1.294,0,0,1,.417.417,1.119,1.119,0,0,1,0,1.154m.449-1.186a2.767,2.767,0,0,0-.321-.513,1.4,1.4,0,0,0-.513-.321,1.511,1.511,0,0,0-1.219,0,1.8,1.8,0,0,0-.481.321,1.4,1.4,0,0,0-.321.513,1.511,1.511,0,0,0,0,1.219,1.724,1.724,0,0,0,.321.513,1.579,1.579,0,0,0,.481.321,1.511,1.511,0,0,0,1.219,0,1.8,1.8,0,0,0,.481-.321,1.4,1.4,0,0,0,.321-.513,1.875,1.875,0,0,0,.128-.609,1.285,1.285,0,0,0-.1-.609"
							transform="translate(-263.18 -49.055)"
					/>
					<path
							id="Path_70"
							data-name="Path 70"
							d="M407.585,92.489h.224c.064,0,.1,0,.128.032a.227.227,0,0,1-.128.385h-.224Zm.77.449a.545.545,0,0,0,0-.513.328.328,0,0,0-.192-.16.6.6,0,0,0-.257-.064H407.2v1.443h.385v-.481h.224l.224.481h.417l-.289-.545a1.017,1.017,0,0,0,.192-.16"
							transform="translate(-265.218 -50.825)"
					/>
					<g
							id="Group_17"
							data-name="Group 17"
							transform="translate(113.378 50.419)"
					>
						<path
								id="Path_71"
								data-name="Path 71"
								d="M318.8,125.953H318l2.309-6.253h.77l2.277,6.253h-.8l-1.86-5.259h-.064Zm.289-2.437H322.3v.673H319.09Z"
								transform="translate(-318 -119.7)"
						/>
						<path
								id="Path_72"
								data-name="Path 72"
								d="M346.072,119.7h.77v4.137a2.372,2.372,0,0,1-.289,1.154,2.009,2.009,0,0,1-.834.8,2.995,2.995,0,0,1-2.565,0,2.3,2.3,0,0,1-.866-.8,2.2,2.2,0,0,1-.289-1.154V119.7h.77v4.072a1.562,1.562,0,0,0,.192.8,1.359,1.359,0,0,0,.577.545,2.191,2.191,0,0,0,1.8,0,1.359,1.359,0,0,0,.577-.545,1.646,1.646,0,0,0,.192-.8V119.7Z"
								transform="translate(-334.304 -119.7)"
						/>
						<path
								id="Path_73"
								data-name="Path 73"
								d="M366.67,119.7v6.253h-.77V119.7Zm1.154,6.253h-1.507v-.673h1.443a2.532,2.532,0,0,0,1.283-.289,1.7,1.7,0,0,0,.77-.866,3.5,3.5,0,0,0,0-2.629,1.867,1.867,0,0,0-.738-.834,2.364,2.364,0,0,0-1.219-.289h-1.571V119.7h1.635a3.084,3.084,0,0,1,1.571.385,2.634,2.634,0,0,1,.994,1.058,4.118,4.118,0,0,1,0,3.335,2.414,2.414,0,0,1-1.026,1.09A3.248,3.248,0,0,1,367.824,125.953Z"
								transform="translate(-350.54 -119.7)"
						/>
						<path
								id="Path_74"
								data-name="Path 74"
								d="M389.97,119.7v6.253h-.77V119.7Z"
								transform="translate(-366.368 -119.7)"
						/>
						<path
								id="Path_75"
								data-name="Path 75"
								d="M399.4,120.373V119.7h4.682v.673h-1.956v5.58h-.77v-5.58Z"
								transform="translate(-373.298 -119.7)"
						/>
					</g>
				</g>
			</svg>
	)
}

export default AuditLogo_SVG
