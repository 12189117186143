const BaseLogo_SVG = ({...props}) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='138.912'
			height='28.924'
			viewBox='0 0 138.912 28.924'

			{...props}
		>
			<g
				id='Group_11'
				data-name='Group 11'
				transform='translate(0 0)'
			>
				<g
					id='Group_10'
					data-name='Group 10'
					transform='translate(0 0)'
				>
					<path
						id='Path_38'
						data-name='Path 38'
						d='M6.89,44.642A5.039,5.039,0,0,1,1.851,39.6V24.889h4.59V39.223a1.794,1.794,0,0,0,1.794,1.794h.5v3.625Z'
						transform='translate(-1.204 -16.183)'
					/>
					<path
						id='Path_39'
						data-name='Path 39'
						d='M2.926,5.851a3.082,3.082,0,0,1-.5-.041A2.926,2.926,0,0,1,2.926,0a3.047,3.047,0,0,1,.5.041,2.926,2.926,0,0,1-.5,5.809Z'
						transform='translate(0 0)'
					/>
					<path
						id='Path_40'
						data-name='Path 40'
						d='M168.067,26.449c-4.27,0-4.27,2.717-4.27,2.93,0,.692,0,2.848,4.27,2.848a11.582,11.582,0,0,0,4.63-.988s-.45-4.79-4.63-4.79m-1.844,17.179c-7.156,0-10.487-5.446-10.655-10.955h4.182c.2,3.38,2.039,7.121,6.677,7.121a5.7,5.7,0,0,0,5.82-4.457,22.86,22.86,0,0,1-4.539.655c-7.412,0-7.745-5.765-7.745-6.613,0-3.391,2.177-6.764,8.1-6.764,6.357,0,8.2,6.508,8.2,6.508s2.166-1.572,2.166-5.713h5.538a2.313,2.313,0,0,1,.682.879s1.172-1.674,5.191-1.674c5.249,0,6.369,2.533,6.369,2.533s1.225-2.533,6.369-2.533a8.675,8.675,0,0,1,8.665,8.665V43.163h-4.59V31.28a4.074,4.074,0,0,0-8.148,0V43.163h-4.59V31.28a4.057,4.057,0,0,0-2.78-3.874,4.368,4.368,0,0,0-1.294-.2,4.079,4.079,0,0,0-4.074,4.074V43.163h-4.59V28.332s-1.224,4.017-4.242,5.586c0,5.877-4.17,9.711-10.718,9.711'
						transform='translate(-101.151 -14.704)'
					/>
					<path
						id='Path_41'
						data-name='Path 41'
						d='M100.218,43.628c-6.612,0-10.72-4.026-10.72-10.506,0-6.382,4.208-10.507,10.72-10.507,3.473,0,6.324,1.164,8.029,3.279a8.1,8.1,0,0,1,1.5,6.78h-5.163a8.038,8.038,0,0,0,.328-2.033c0-1.45-1.024-4.191-4.9-4.191-3.358,0-5.363,2.407-5.363,6.44,0,4.271,1.912,6.905,5.247,6.905a5.181,5.181,0,0,0,4.852-2.714h5.506c-.395,2.384-3.61,6.549-10.039,6.549'
						transform='translate(-58.192 -14.704)'
					/>
					<path
						id='Path_42'
						data-name='Path 42'
						d='M332.6,26.449c-2.544,0-4.615,1.164-5.155,3.883a4.711,4.711,0,0,0-.072.785H337.5s.009-.484,0-.654c-.163-3.169-3.153-4.014-4.894-4.014m.2,17.179c-6.612,0-10.72-4.026-10.72-10.506,0-6.382,4.208-10.507,10.72-10.507,3.5,0,6.231,1.109,7.895,3.207a9.9,9.9,0,0,1,1.541,8.151H327.374c0,.308.168,5.822,5.111,5.822a5.2,5.2,0,0,0,4.852-2.714h5.507c-.4,2.384-3.61,6.549-10.039,6.549'
						transform='translate(-209.421 -14.704)'
					/>
					<path
						id='Path_43'
						data-name='Path 43'
						d='M40.828,43.164V31.383a4.32,4.32,0,0,0-3.409-4.123,4.271,4.271,0,0,0-.665-.053A4.079,4.079,0,0,0,32.68,31.28V43.164H28.09v-12c-.008-.222-.208-4.982-2.155-7.753h4.947a2.977,2.977,0,0,1,.706.989s1.589-1.784,5.166-1.784a8.674,8.674,0,0,1,8.664,8.664V43.164Z'
						transform='translate(-16.863 -14.705)'
					/>
					<path
						id='Path_44'
						data-name='Path 44'
						d='M390.187,75.237a1.267,1.267,0,0,1-.453.453,1.223,1.223,0,0,1-.633.168,1.21,1.21,0,0,1-.629-.168,1.267,1.267,0,0,1-.451-.453,1.262,1.262,0,0,1,0-1.26,1.267,1.267,0,0,1,.451-.453,1.21,1.21,0,0,1,.629-.168,1.223,1.223,0,0,1,.633.168,1.267,1.267,0,0,1,.453.453,1.269,1.269,0,0,1,0,1.26m.483-1.289a1.719,1.719,0,0,0-.908-.909,1.708,1.708,0,0,0-1.321,0,1.729,1.729,0,0,0-.543.367,1.706,1.706,0,0,0-.365.543,1.714,1.714,0,0,0,0,1.319,1.7,1.7,0,0,0,.365.542,1.729,1.729,0,0,0,.543.367,1.643,1.643,0,0,0,.659.133,1.663,1.663,0,0,0,.664-.133,1.709,1.709,0,0,0,.906-.909,1.715,1.715,0,0,0,0-1.319'
						transform='translate(-251.89 -47.403)'
					/>
					<path
						id='Path_45'
						data-name='Path 45'
						d='M390.913,75.778h.257a.238.238,0,0,1,.151.051.2.2,0,0,1,.066.165.215.215,0,0,1-.064.172.229.229,0,0,1-.155.056h-.254Zm.826.5a.508.508,0,0,0,.077-.285.5.5,0,0,0-.074-.279.494.494,0,0,0-.2-.177.572.572,0,0,0-.272-.058H390.5v1.567h.41v-.529h.26l.243.529h.45l-.3-.608a.492.492,0,0,0,.175-.16'
						transform='translate(-253.907 -49.077)'
					/>
				</g>
			</g>
		</svg>
	)
}

export default BaseLogo_SVG
